//SINGLE LOCATION

import React from "react"
import { graphql, Link } from 'gatsby'
import Wrapper from "../components/layout/wrapper"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Style from "./location.module.scss"

const LocationTemplate = ( { data, pageContext: { location } } ) => {
    const upperCaseCity = location.replace(/^\w/, c => c.toUpperCase());
    return (
        <Layout>
        <SEO title={upperCaseCity}/>
        <section className="page-hero">
          <Wrapper>
            <h1>Which school in {upperCaseCity} will you  be attending?</h1>
          </Wrapper>
        </section>
        <Wrapper>
            <section className={Style.locationSection}>
                <ul className={Style.locationListGroup}>
                    {data.allPrismicSchool.edges.map(x => (
                        <li key={x.node.uid} className={Style.locationListItem}>
                            <Link to={`/listings/${x.node.slugs}`} className={Style.locationLink}>
                            <div className={Style.locationInner}>
                            <strong>{x.node.data.school}</strong>
                            <div className={Style.locationIcon}>
                            <svg class="terms__arrow" width="36px" height="11px" viewBox="0 0 36 11"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M32.3685904,4.8125 L26.0322131,1.24828777 L25.5964254,1.00315715 L26.0866866,0.131581612 L26.5224744,0.376712231 L34.5224744,4.87671223 L35.2972082,5.3125 L34.5224744,5.74828777 L26.5224744,10.2482878 L26.0866866,10.4934184 L25.5964254,9.62184285 L26.0322131,9.37671223 L32.3685904,5.8125 L26.2773438,5.8125 L0.77734375,5.8125 L0.27734375,5.8125 L0.27734375,4.8125 L0.77734375,4.8125 L26.2773438,4.8125 L32.3685904,4.8125 Z" fill="currentColor" fill-rule="nonzero"></path></g></svg>
                            </div>
                            </div>
                            </Link>
                            <hr />
                        </li>
                    ))}
                </ul>
            </section>
        </Wrapper>
      </Layout>
    )
}

export default LocationTemplate

export const query = graphql`
query LocationPageQuery($location: String!) {
  allPrismicSchool(filter: {data: {location: {document: {elemMatch: {data: {location: {eq: $location}}}}}}}) {
    edges {
      node {
        uid
        slugs
        data {
          school
        }
      }
    }
  }
}
`